import React, { useEffect, useState } from 'react';
import Resizer from "react-image-file-resizer";
import { Headphone } from '../../../Constant/api';
const AnswerOptionsSelect = ({
  AnswerArray,
  setData, Data,
  DataValue,
  option,
  ArrayIndex,
  QuestionsSolved, setQuestionsSolved,
  index,
  correct,
  setTotalCorrect, image

}) => {
  return (
    <div className="answer-option-style"
      style={{ display: 'flex', }}
    >
      <div style={{ marginRight: "5px", cursor: "pointer" }}
        onClick={() => {
          setData(option)
          // if (AnswerArray[ArrayIndex].answerChoosen == "") {
          //   setQuestionsSolved(QuestionsSolved + 1)
          // }
          const ArrayJSONvalue = AnswerArray[ArrayIndex]
          AnswerArray[ArrayIndex] = {
            image: image,
            number: DataValue,
            correct_answer: correct,
            answerChoosen: option
          }
        }}
      >
        {Data == option ?
          <div style={{
            border: "4px solid #000",
            borderRadius: "20px",
            width: "30px",
            height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
            fontSize: "16px", fontWeight: "bold", color: "#fff", backgroundColor: "#000"
          }}>
            &nbsp;{index}&nbsp;
          </div>
          :
          <div style={{
            border: "4px solid black",
            borderRadius: "20px",
            width: "30px",
            height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
            fontSize: "16px", fontWeight: "bold"
          }}>
            &nbsp;{index}&nbsp;
          </div>
        }
        {/* {Data == option ?
          <i className="fa fa-check-circle-o" style={{ fontSize: 25, color: "green" }}></i>
          : <i className="fa fa-circle-o" style={{ fontSize: 25 }}></i>
        } */}
      </div>

      <div className={Data == option ? "active-option2" : "passive-option2"}>
        <span className="answer-text" >
          {DataValue}
        </span>
      </div>
    </div>
  )
}

export default function ThridScreenComponent(props) {
  const {
    oneQuestionData,
    AnswerArray, ArrayIndex,
    setQuestionsSolved, QuestionsSolved,
    setTotalCorrect
  } = props;
  const [AnswerSelected, setAnswerSelected] = React.useState("")
  const [ImageSelect, setImageSelect] = React.useState("")
  const [option1, setOption1] = React.useState("")
  const [option2, setOption2] = React.useState("")
  const [option3, setOption3] = React.useState("")
  const [correct, setCorrect] = React.useState("")
  useEffect(() => {
    if (AnswerArray[ArrayIndex].answerChoosen !== "") {
      setAnswerSelected(AnswerArray[ArrayIndex].answerChoosen)
    }
    var random = Math.floor(Math.random() * 3) + 1
    var randomcache = Math.floor(Math.random() * (Number(oneQuestionData.number) + 7)) + 1
    var random2 = (randomcache + random) == Number(oneQuestionData.number) ? (randomcache + random + 1) : (randomcache + random)
    var random3 = randomcache == Number(oneQuestionData.number) ? randomcache - 1 : randomcache
    if (random == 1) {
      // setOption1(oneQuestionData.number)
      setOption1(oneQuestionData.number)
      setOption2(random2)
      setOption3(random3)
      setCorrect("option 1")
    }
    else if (random == 2) {
      setOption2(oneQuestionData.number)
      setOption1(random2)
      setOption3(random3)
      setCorrect("option 2")
    }
    else {
      setOption3(oneQuestionData.number)
      setOption1(random2)
      setOption2(random3)
      setCorrect("option 3")
    }
  }, [])
  var random = Math.floor(Math.random() * 3) + 1

  return (
    <div>
      <div className="row question-block">
        <div className="col-md-8 ">
          <div style={{
            margin: "3px", padding: "3px",
            border: "1px solid #999", overflowY: 'scroll'
          }}
            className="question-height"
          >
            {/* <div
              style={{ padding: "10px 0 0 0" }}
            >
              <h3 className="question-font" style={{ fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                {oneQuestionData.question}
              </h3>
            </div> */}
            <div className="question-description">

              <>
                <div className="center"
                  onClick={() => {
                    setImageSelect(oneQuestionData.image)
                  }}
                >
                  <img
                    src={oneQuestionData.image}
                    style={{ objectFit: "contain", width: "350px", height: "350px" }}
                  />
                </div>
              </>

            </div>

          </div>
        </div>
        <div className="col-md-4">
          <div style={{ border: "1px solid #999", overflowY: 'scroll', }}
            className="question-height"
          >
            <AnswerOptionsSelect
              AnswerArray={AnswerArray}
              setData={setAnswerSelected} Data={AnswerSelected}
              DataValue={option1}
              option={"option 1"}
              ArrayIndex={ArrayIndex}
              QuestionsSolved={QuestionsSolved} setQuestionsSolved={setQuestionsSolved}
              index={1}
              correct={correct}
              setTotalCorrect={setTotalCorrect}
              image={oneQuestionData.image}
            />
            <AnswerOptionsSelect
              AnswerArray={AnswerArray}
              setData={setAnswerSelected} Data={AnswerSelected}
              DataValue={option2}
              option={"option 2"}
              ArrayIndex={ArrayIndex}
              QuestionsSolved={QuestionsSolved} setQuestionsSolved={setQuestionsSolved}
              index={2}
              correct={correct}
              setTotalCorrect={setTotalCorrect}
              image={oneQuestionData.image}
            />
            <AnswerOptionsSelect
              AnswerArray={AnswerArray}
              setData={setAnswerSelected} Data={AnswerSelected}
              DataValue={option3}
              option={"option 3"}
              ArrayIndex={ArrayIndex}
              QuestionsSolved={QuestionsSolved} setQuestionsSolved={setQuestionsSolved}
              index={3}
              correct={correct}
              setTotalCorrect={setTotalCorrect}
              image={oneQuestionData.image}
            />
          </div>
        </div>
      </div>
      {ImageSelect !== "" &&
        <div className="modal center"
          onClick={() => {
            setImageSelect("")
          }}
        >
          <img className="image-modal"
            src={ImageSelect}
          />

        </div>
      }
      {console.log(AnswerArray)}

    </div>
  )
}
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from '../../Student/ExamDetails';

export default function StartDemoExamNonLogin(props) {
	const location = useLocation()
	const [ExamData, setAllExamList] = React.useState({});
	const [ExamFetch, setExamFetch] = React.useState(false);
	const [NotFoundData, setNotFound] = React.useState(false)
	const [reload, setReload] = React.useState(false)
	const [choose, setChoose] = React.useState(0)
	const [AnswerArray, setAnswerArray] = React.useState([]);
	const [ResultData, setResultData] = React.useState({});

	const fetchExam = (slug) => {
		setExamFetch(false)
		setReload(true)
		setNotFound(false)
		const id = store.getState().LoginState.userID
		AdsGETApi({ per_page: 1 }, slugs.db_slug_demo_exam)
			.then((res) => {
				if (res.data.length == 1) {
					setAllExamList(res.data[0])
				}
				else {
					setNotFound(true)
				}
				setExamFetch(true)
				setReload(false)
			})
			.catch(err => {
				console.log(err)
				console.log(err.response.data)
			})

	}
	useEffect(() => {
		fetchExam(location.pathname.slice(slugs.start_exam.length + 1))
	}, [])

	return (
		<div style={{ backgroundColor: "#eee", minHeight: "90vh" }}>
			<div>
				<div className="row">
					<div className="col-sm-2 col-1"></div>
					<div className="col-sm-8 col-10 form-view m-3">
						{ExamFetch &&
							<>
								{NotFoundData ?
								<>
									<NotFound />
									</> :
									<>
										<FirstScreenExam
											ExamData={ExamData}
											setReload={setReload}
											choose={choose}
											setChoose={setChoose}
											AnswerArray={AnswerArray}
											setAnswerArray={setAnswerArray}
											ResultData={ResultData} setResultData={setResultData}
										/>
									</>
								}
							</>
						}
					</div>
				</div>
			</div>
			{reload &&
				<div className="modal" style={{ padding: "50vw" }}>
					<div className="dot-pulse"></div>
				</div>
			}
		</div>
	)
}
import React from 'react'
export default function NotFound(props) {
  return (
    <div className="m-1"
      style={{ height: "50vh", width: "97%", backgroundColor: "#fff" }}
    >
      <header class="m-3">
        <h1 class="p-5">The page can’t be found.</h1>
      </header>
      <div class="px-5">
        <p>It looks like nothing was found at this location.</p>
      </div>
    </div>
  )
}
